@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 0.4em solid #0c71d7;
  border-top: 0.4em solid #d9ebff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spinner 0.8s ease-in infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 100%;
  position: fixed;
  z-index: 100000;
}

.loading-container {
  background: transparent !important;
}

/* width */
.statDropDown::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px;
}

/* Track */
.statDropDown::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.statDropDown::-webkit-scrollbar-thumb {
  background: #f7f7f7;
}

/* Handle on hover */
.statDropDown::-webkit-scrollbar-thumb:hover {
  background: #f7f7f7;
}

/* width */
.dark-scrollbar::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px;
}

/* Track */
.dark-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.dark-scrollbar::-webkit-scrollbar-thumb {
  background: #030b1f;
}

/* Handle on hover */
.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #030b1f;
}

/* width */
.dark-scrollbar-horizontal::-webkit-scrollbar {
  height: 2px;
  border-radius: 2px;
}

/* Track */
.dark-scrollbar-horizontal::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.dark-scrollbar-horizontal::-webkit-scrollbar-thumb {
  background: #030b1f;
}

/* Handle on hover */
.dark-scrollbar-horizontal::-webkit-scrollbar-thumb:hover {
  background: #030b1f;
}

.MuiTabs-indicator {
  background-color: #0c71d7 !important;
}

td span {
  color: black;
}

.MuiFormControlLabel-root .MuiTypography-root {
  margin-left: 8px !important;
}

.fixed-layout-content::-webkit-scrollbar {
  width: 0;
  display: none;
}

/* Track */
.fixed-layout-content::-webkit-scrollbar-track {
  background: #888;
  border-radius: 5px;
}

/* Handle */
.fixed-layout-content::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
.fixed-layout-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0c71d7 !important;
}

.auto-complete-option {
  padding: 8px 16px;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
}

.auto-complete-option:hover {
  background-color: #f1f8ff;
}

.auto-complete-option-small {
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  color: rgb(11 25 64);
}

.auto-complete-option-small:hover {
  background-color: #f1f8ff;
}

.MuiAutocomplete-popper .MuiAutocomplete-option:hover {
  background-color: #f1f8ff;
}

.MuiAutocomplete-popupIndicator,
.MuiAutocomplete-clearIndicator {
  padding: 0 !important;
}

.phone::-webkit-inner-spin-button {
  display: none;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #0b1940;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.number-input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.orderscroll {
  width: 100%;
  overflow: auto;
}

.orderscroll > Table {
  min-width: 1400px;
}

.global-search::-webkit-scrollbar,
.map::-webkit-scrollbar {
  width: 4px;
}

.global::-webkit-scrollbar-thumb,
.map::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.global-search::-webkit-scrollbar-thumb:hover,
.map::-webkit-scrollbar-thumb:hover {
  background-color: '#555';
}

.global-search::-webkit-scrollbar-track,
.map::-webkit-scrollbar-track {
  background-color: '#f1f1f1';
}

.image-preview-container {
  width: auto;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 8px !important;
  position: relative;
  cursor: pointer;
}

.image-preview-doc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.image-preview-container:hover .image-preview-doc {
  opacity: 1 !important;
}

.image-preview {
  width: 100%;
  height: 100%;
}

#jsd-widget {
  z-index: 1270 !important;
}

.input-text input::placeholder {
  font-size: 12px !important;
  color: #000;
}

.rdrStaticRangeLabel {
  color: black !important;
}

.react-switch input {
  display: none;
}

.react-switch .react-switch-bg {
  border: 1px solid black !important;
}

.react-switch-checked .react-switch-bg {
  border: 1px solid #0c71d7 !important;
}

.node-card:hover {
  box-shadow:
    0px 3.200000047683716px 7.199999809265137px 0px #00000021,
    0px 0.6000000238418579px 1.7999999523162842px 0px #0000001a !important;
}

.mask-text {
  font: inherit;
  color: currentColor;
  width: 96%;
  height: 10%;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: lightgray;
  padding: 20px 14px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.mask-text:focus {
  outline: 1px #3f51b5 !important;
}

.mask-text:focus-within {
  border-color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
}

.ctower-map-holder-height {
  height: calc(max(545px, 100vh - 200px));
}

.ctower-map-height {
  height: calc(max(450px, 100vh - 295px));
}

.ctower-bottom-position {
  height: calc(max(450px, 100vh - 295px));
  bottom: calc(max(450px, 100vh - 295px));
}

.ctower-bottom-right-position {
  bottom: calc(200px);
  left: calc(100% - 51px);
}

.MuiClockPicker-root .MuiButtonBase-root {
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 100px;
}

.inventory-table {
  max-height: calc(100vh - 310px);
}

.inventory-report-table {
  max-height: calc(100vh - 360px);
}

.MuiDialog-paperWidthSm {
  max-width: 75% !important;
  min-width: 0%;
}

.color-headerCell {
  color: #67718c;
}

.page-break-bin {
  page-break-after: always; /* Forces a page break after each QR code */
}
