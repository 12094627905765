@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;300;400;500;600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.web-app-default-font {
  font-family: 'Roboto', sans-serif;
}

.no-select {
  user-select: none;
}

#demo-customized-select {
  width: 200px !important;
  padding: 10px !important;
}

#demo-simple-select-label {
  left: 20px !important;
  top: -12px !important;
}

#customEditSelect .MuiFormLabel-filled {
  top: -5px !important;
  left: 15px !important;
}

.emptyRecord {
  position: fixed;
  top: 52%;
  left: 50%;
}

#statusFont {
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.exportBtn {
  position: absolute !important;
  left: 90px !important;
  top: 1px !important;
  background: #2b3a53 !important;
  color: white;
  padding: 6px 22px !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  cursor: pointer !important;
}

.submitBtn {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #4b89c8 !important;
  border: 2px solid #4b89c8;
  border-radius: 8px;
  color: #ffffff;
  width: 95%;
  height: 32px;
  cursor: pointer !important;
}

.submitBtns {
  width: 192px;
  height: 32px;
  border: none;
  background: #4b89c8 !important;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer !important;
}

.table {
  border: 1px solid #dcdcdc;
  border-radius: 20px;
}

.cancelRouteBtn {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #ffffff !important;
  border: 2px solid #4b89c8;
  border-radius: 8px;
  color: #4b89c8;
  width: 95%;
  height: 32px;
  cursor: pointer !important;
}

.exportCustomBtn {
  width: 172px;
  height: 32px;
  background: #4b89c8;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
}

.orderCustomerSec div div div {
  padding: 0px !important;
  border-radius: 8px !important;
}

.orderCustomerSec div div label {
  top: -8px !important;
}

.currentStat {
  background-color: #4b89c8 !important;
}

.exportCustomBtn:focus {
  outline: none;
}

.react-confirm-alert-body h1 {
  margin-bottom: 30px;
}

#margin-auto {
  margin: auto !important;
}

.switch {
  display: flex !important;
}

.formTitle {
  padding: 5px 15px;
  background-color: #f0fbff;
  border-radius: 8px 8px 0 0;
  color: #807a7a;
  border-bottom: 1px solid #eee9e9;
}

.notificationBtn {
  background-color: #46c6ef !important;
  padding: 4px 35px !important;
  border-radius: 12px !important;
}

.parentTextArea {
  margin: auto !important;
  padding: 25px 0;
}

.parentTextArea div div div {
  border-radius: 8px;
}

.formFooter {
  padding: 14px 28px !important;
}

.footerBtn {
  background-color: #46c6ef !important;
  text-transform: inherit !important;
}

.textAreaForm {
  border: 1px solid #eee9e9;
  padding: 0 0 14px 0;
  border-radius: 8px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  height: 395px;
}

.react-confirm-alert-button-group {
  margin-top: 36px !important;
  justify-content: center !important;
}

.dialogSettings .MuiDialog-paper {
  width: 990px !important;
}

.draftIconSetting {
  top: 4px;
  left: 55px;
}

.react-confirm-alert-overlay {
  background: none !important;
  z-index: 1310 !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.MuiDialog-paperWidthSm {
  max-width: 60% !important;
  min-width: 0% !important;
}

.w-50px {
  width: 50px !important;
}

.w-50 {
  width: 50% !important;
}

.w-150px {
  width: 150px !important;
}

.w-100px {
  width: 100px !important;
}

#routeStatusTag {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: auto;
}

.downloadPods div {
  min-width: 100px !important;
}

.filterTitle {
  font-size: 24px;
}

.removeClose {
  opacity: 0 !important;
  transition: 0.5s;
  z-index: 0 !important;
  left: -473px;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  top: 1px;
  border-radius: 20px 0 0px 20px;
  height: 34px;
  width: 36px;
  padding: 5px;
  background: #2b3a53 !important;
  color: white !important;
}

.customTableClass {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-radius: 12px;
  padding: 0px !important;
  height: 395px !important;
}

.filterFocused {
  transition: 0.5s !important;
  right: -235px !important;
  top: 8px !important;
  opacity: 1 !important;
  z-index: 99 !important;
  font-size: 24px !important;
  height: 35px !important;
}

.notificationField div div,
.notificationField div {
  border-radius: 10px;
}

.autoCompleteField div div,
.autoCompleteField div {
  border-radius: 10px;
}

.hallsAutoCompleteField > div > div {
  height: 46px !important;
  margin-top: 9px !important;
  border: 1px solid rgba(15, 68, 122, 0.5) !important;
}

.hallsAutoCompleteField2 > div > div {
  height: 46px !important;
  margin-top: 9px !important;
  border: 0px solid #9da3b3 !important;
  text-align: center;
}

.referenceField div {
  border-radius: 10px !important;
}

.referenceField div input {
  padding: 8px 12px !important;
}

.referenceField div fieldset {
  border: 2px solid #c1d6ec !important;
}

.referenceField {
  width: 50% !important;
}

.imagePreviewSec {
  width: 110px;
  height: 115px;
  margin-right: 10px;
}

.podSize {
  position: absolute;
  top: -7px;
  background: #4b89c8;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 50%;
  left: -5px;
}

.border-radius div div,
.border-radius div {
  border-radius: 10px !important;
}

.podImg {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover !important;
  border-radius: 6px;
}

.podNotUploaded {
  filter: opacity(0.5);
  width: 30px !important;
}

.podUploaded {
  width: 30px !important;
}

.autoCompleteField {
  width: 100% !important;
}

.locationsFields {
  width: 100% !important;
}

.full-width {
  width: 100% !important;
}

.autoCompleteFieldthird {
  width: 90% !important;
}

.quantityField {
  width: 100% !important;
}

.closeSearch {
  left: -495px;
  opacity: 1;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px 0 0px 20px;
  height: 44px;
  width: 36px;
  padding: 5px;
  background: transparent !important;
  color: #2b3a53 !important;
  z-index: 99;
  transition: 0.5s !important;
}

.warehouseCloseSearch {
  left: -520px;
  opacity: 1;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px 0 0px 20px;
  height: 44px;
  width: 36px;
  padding: 5px;
  background: transparent !important;
  color: #2b3a53 !important;
  z-index: 99;
  transition: 0.5s !important;
}

.table-header {
  background-color: #f6f8fb;
  border-radius: 20px;
}

.invoiceAdditionalNote {
  padding: 10px;
  font-weight: 400;
}

.rdrStartEdge[style],
.rdrEndEdge[style] {
  color: #0c71d7 !important;
  border-radius: 50%;
  border-top-right-radius: none;
  border-top-left-radius: none;
  right: 0;
  left: 0;
  top: 1px;
  bottom: 2px;
}

.rdrHovered {
  background: initial !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  border: none;
}

.rdrDays .rdrDay .rdrStartEdge + span span,
.rdrEndEdge + span span {
  color: #fff !important;
}

.first-cell {
  border-radius: 10px 0 0 0;
  background-color: #f6f8fb;
}

.table-cell {
  background-color: #f6f8fb;
}

.last-cell {
  background-color: #f6f8fb;
  border-radius: 0 10px 0 0;
}

.categoryCloseSearch {
  left: -365px;
  opacity: 1;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px 0 0px 20px;
  height: 44px;
  width: 36px;
  padding: 5px;
  background: transparent !important;
  color: #2b3a53 !important;
  z-index: 99;
  transition: 0.5s !important;
}

.userCloseSearch {
  left: -463px;
  opacity: 1;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px 0 0px 20px;
  height: 44px;
  width: 36px;
  padding: 5px;
  background: transparent !important;
  color: #2b3a53 !important;
  z-index: 99;
  transition: 0.5s !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

#pt-3 {
  padding-top: 8px !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

#float-left {
  float: left !important;
}

#routeStyles {
  float: left !important;
  padding-top: 8px !important;
}

.setSearchBar:focus-within + .searchIcon {
  display: none;
}

.print-logo {
  width: 200px !important;
  object-fit: cover;
}

.print-logo-parent {
  display: none !important;
  width: 100px !important;
}

.searchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 8px;
  left: 0;
  margin: auto;
  right: 134px;
  z-index: 99;
}

.inventorySearchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 8px;
  left: 0;
  margin: auto;
  right: 6px;
  z-index: 99;
}

.roleSearchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 9px;
  left: 0;
  margin: auto;
  right: 108px;
  z-index: 99;
}

.bb-0 {
  border-bottom: 0px !important;
}

.inwardSearchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 9px;
  left: 0;
  margin: auto;
  right: 194px;
  z-index: 99;
}

.inwardCloseSearch {
  left: -550px;
  opacity: 1;
  position: absolute;
  right: 0;
  margin: auto;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px 0 0px 20px;
  height: 44px;
  width: 36px;
  padding: 5px;
  background: transparent !important;
  color: #2b3a53 !important;
  z-index: 99;
  transition: 0.5s !important;
}

.UserSearchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 8px;
  left: 0;
  margin: auto;
  right: 110px;
  z-index: 99;
}

.warehouseSearchIcon {
  right: 158px !important;
}

.uomSearchIcon {
  right: 6px !important;
}

.categorySearchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 31px;
  top: 8px;
  left: 0;
  margin: auto;
  right: 0;
  z-index: 99;
}

.filled-tab {
  color: white !important;
  background-color: #0c70d7 !important;
}

.uploadBtn {
  width: 100px !important;
  float: right !important;
}

.primaryColor {
  background-color: #2b3a53 !important;
  color: white !important;
  padding: 3px !important;
}

.focusedInput {
  width: 400px !important;
  transition: 0.5s;
  z-index: 0;
  padding-left: 40px !important;
}

.setSearchBar {
  z-index: 99;
  height: 45px !important;
}

.setCategorySearchBar {
  z-index: 99;
  height: 45px !important;
  margin-bottom: -15px;
}

.multipleTableCols {
  position: absolute;
  width: 100%;
  right: 0;
  justify-content: space-around;
}

.multipleTableCols span:first-child {
  color: #979797 !important;
}

.multipleTableCols span:first-child + span {
  color: #979797 !important;
}

/* Label CSS*/
.phone-label {
  color: gray;
  position: absolute;
  top: 24px;
  left: 22px;
  transition: 0.2s;
}

.search-box {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray;
  height: 50px;
  border-radius: 50px;
  padding: 10px;
  margin-top: 25px;
}

.search-btn {
  color: black;
  float: right;
  width: 50px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 2s;
}

.search-cancel {
  color: black;
  float: left;
  width: 40px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 2s;
}

.searchIcons {
  font-size: 32px;
}

.search-input {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 20px;
  transition: 0.4s;
  line-height: 50px;
  width: 0;
}

.search-box:hover .search-input {
  width: 250px;
  padding: 0 7px;
}

.activeSearch {
  width: 250px;
  padding: 0 7px;
}

.loaderScreen {
  width: 84%;
  text-align: center;
  height: 100vh;
  position: absolute;
  overflow-y: hidden !important;
  z-index: 10000000;
  background: rgba(0, 0, 0, 0.3) !important;
  padding-top: 10%;
}

#companyPhone:not(:placeholder-shown) + .phone-label {
  top: 0% !important;
  left: 3%;
  font-size: 12px;
  padding: 0 4px;
  background: white;
  color: gray !important;
}

.routeTableHead {
  background: #fafafc !important;
  border: 1px solid rgba(75, 137, 200, 0.2) !important;
}

.additionalTextArea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a7c1df;
  opacity: 1;
  /* Firefox */
}

.additionalTextArea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a7c1df;
}

.additionalTextArea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a7c1df;
}

#companyPhone:focus + .phone-label {
  top: 0% !important;
  left: 3%;
  font-size: 12px;
  padding: 0 4px;
  background: white;
  color: #0b61fc !important;
}

#setCurrentTitleSet {
  margin-left: 86px !important;
  transition: 0.2s;
}

.parentCompany {
  position: relative !important;
}

/* Label CSS*/

.customPadding {
  padding-left: 16vw;
  transition: 0.2s;
}

.MuiInput-underline:before {
  border-bottom: 0px solid lightgray !important;
}

.addNewRow {
  width: 100px;
}

.dropDown {
  border: 1px solid lightgray !important;
  border-radius: 8px;
  margin-top: 4px !important;
}

.dropdown {
  background-color: white !important;
  z-index: 9999 !important;
  position: relative;
  width: 200px;
}

.profileIcon {
  font-size: 24px;
}

.profileTitle {
  position: absolute;
  top: 4px;
  left: 40px;
}

.MuiSelect-select:focus {
  background: none !important;
}

.dropdownLable {
  top: -6px !important;
  left: 10px !important;
  background: white !important;
  padding: 0px 10px !important;
}

.collapseIcon {
  position: absolute;
  right: 22px;
  top: 22px;
  font-size: 30px !important;
  color: white;
  cursor: pointer;
}

#currentTitle {
  transition: 0.2s;
}

.mainNavStyle {
  background: #0b1940;
  box-shadow:
    0px 6px 30px 5px rgba(0, 0, 0, 0.12),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  transition: 0.2s;
}

.MuiDrawer-paperAnchorDockedLeft {
  background: none !important;
  border-right: 0px !important;
}

.dateFilterGrid {
  background: #ffffff;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 4px 4px 16px rgb(75 137 200 / 12%);
  border-radius: 16px;
  z-index: 999;
  position: absolute;
  left: 167px;
  width: 300px;
  padding: 14px 5px;
}

.left-0 {
  left: 0 !important;
}

.taskDateFilter {
  position: absolute;
  left: 150px;
  z-index: 999;
}

.left-27vw {
  left: 27vw;
}

.top-65px {
  top: 65px;
}

.pt-8 {
  padding-top: 8px;
}

.datePickerContainer {
  margin: 10px 0px;
  width: 100%;
}

.dateFilter {
  display: flex;
  background: #edf3fa !important;
  padding: 3px 12px !important;
  border: 1px solid rgba(15, 68, 122, 0.3) !important;
  border-radius: 8px !important;
  cursor: pointer;
  color: #000000;
  height: 36px;
  align-items: center;
}

.calenderIconDate {
  margin-top: 2px;
  margin-right: 6px;
  height: 20px !important;
}

.dateCalendar {
  display: flex;
  background: #edf3fa;
  border-radius: 8px !important;
  width: 340px !important;
  padding: 6px 10px;
  cursor: pointer;
}

.invoiceCalender {
  display: flex;
  background: white !important;
  border-radius: 8px !important;
  width: 100% !important;
  padding: 6px 10px;
  cursor: pointer;
  height: 50px;
}

.filter {
  display: flex;
  background: #ffffff !important;
  padding: 0 !important;
  border: 1px solid rgba(15, 68, 122, 0.3) !important;
  border-radius: 8px !important;
  cursor: pointer;
  color: #000000;
}

.dateSeperater {
  padding: 0 10px;
}

.svgIcons {
  width: 38px;
}

.dateField {
  margin: auto;
  padding: 0 15px;
  width: 300px;
}

.dateFilterOpt {
  padding: 10px 14px;
  cursor: pointer;
}

.calenderIcon {
  background-color: #4b89c8;
  padding: 8px 8px;
  border-radius: 8px;
}

#navItem {
  padding-left: 16px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-group {
  margin-left: 0px !important;
  margin-top: 12px !important;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: -12px !important;
}

.MuiTreeItem-iconContainer {
  position: relative;
  margin-left: 10px;
}

.tableCell::after {
  content: '|';
  position: absolute;
  top: 15px;
  right: 0;
  width: 1.5px;
}

.warehouseFilterPadding {
  padding: 0 10px;
}

.margin-auto {
  margin: auto !important;
}

.tableHeader {
  background: #edf3fa !important;
  border: 1px solid #c1d6ec !important;
  border-radius: 8px 8px 0px 0px !important;
}

.detailTable {
  border: 1px solid #c1d6ec !important;
  border-radius: 8px !important;
}

.shipmentDetailBtn {
  width: 90% !important;
}

.searchWithFilter {
  width: 650px;
  height: 36px;
  border: 1px solid #87a2bd;
  border-radius: 8px;
  padding-left: 12px;
}

.customPlletizeSearch {
  width: 350px;
  height: 36px;
  border: 1px solid #87a2bd;
  border-radius: 8px;
  padding-left: 12px;
}

.searchBarForProduct {
  width: 100%;
  height: 55px;
  border: 1px solid #87a2bd;
  border-radius: 8px;
}

.cancelBtn {
  width: 192px;
  height: 32px;
  border: none;
  cursor: pointer;
  background: #d9dfe5;
  color: #979b9f !important;
  border-radius: 8px;
}

.select-input-label {
  left: 10px !important;
  top: -7px !important;
  background: white !important;
  z-index: 9 !important;
  width: 40px !important;
  text-align: center !important;
}

.aisleDataTable {
  width: 100% !important;
}

.orderDataTable {
  width: 100vw !important;
}

.bayDataTable {
  width: 100% !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.inputWeight {
  width: calc(100% - 116px);
  border-radius: 8px 0 0 8px !important;
  padding: 0 12px !important;
  background: #ffffff;
  border: 1px solid rgba(15, 68, 122, 0.5);
  height: 55px !important;
}

.inputWeight + span div {
  height: 55px !important;
}

.inputWeight + span div div {
  top: -9px !important;
}

.formFields {
  width: 90% !important;
}

.radioForm {
  width: 60%;
}

.orderCreateRadio {
  margin-right: 10px !important;
  margin-top: 3px !important;
}

.lineSpace {
  border: 1px solid #d0deee !important;
  margin: 30px 0 !important;
}

.lineSpaceWarehouse {
  border: 1px solid rgba(75, 137, 200, 0.2) !important;
  margin: 15px 0 !important;
}

.lineDashed {
  border: 1px dashed rgba(75, 137, 200, 0.2) !important;
  margin: 0% 10% !important;
}

.dashedLineBreak {
  border: 1px dashed #c4c6cb !important;
  margin: 10px 0;
}

.dashedLine {
  border: 1px dashed #c4c6cb !important;
}

.detailLink {
  color: #4b89c8 !important;
  background-color: transparent;
  border: none;
}

.icon {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.routeManagementStatus {
  margin-left: 26px;
  color: white !important;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}

/* .MuiTreeItem-iconContainer svg {
  position: absolute;
  left: 8px;
  font-size: 28px !important;
  top: -15px;
} */

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background: none !important;
}

.childNavItem {
  line-height: 20px !important;
}

.navChildIcon {
  position: absolute;
  left: -18px;
  font-size: 16px;
  top: 12px;
}

.navTitleSpacing {
  padding: 11px 10px !important;
}

.itemRemove a a span span .navChildTitle {
  white-space: nowrap !important;
  overflow: hidden !important;
  opacity: 0 !important;
  font-size: 0px !important;
  transition: 0.2s;
}

.navChildTitle {
  white-space: nowrap !important;
  overflow: hidden !important;
}

.display-inline {
  display: inline !important;
}

.grid-styles {
  height: 1100px !important;
  width: 100% !important;
}

#display-none {
  visibility: hidden !important;
  opacity: 0;
  transition: 0.2s;
}

.display-none {
  display: none !important;
}

.customProductMargin {
  margin-top: 45px;
}

.filterViewSec {
  padding-bottom: 20px;
  width: 455px !important;
  position: absolute;
  background: white;
  z-index: 9;
  opacity: 2;
  left: 12px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 44px;
}

.filterViewSect {
  padding-bottom: 20px;
  width: 455px !important;
  position: absolute;
  background: white;
  z-index: 9;
  opacity: 2;
  left: 195px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 50px;
}

.customFilterViewSec {
  padding-bottom: 20px;
  width: 650px !important;
  position: absolute;
  z-index: 9;
  opacity: 2;
  background: #ffffff;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 4px 4px 16px rgba(75, 137, 200, 0.12);
  border-radius: 8px;
}

.customFilterParent {
  padding: 25px 15px !important;
}

.dateBtn {
  display: block;
  margin: auto;
  margin-top: 25px;
  background: #2b3a53;
  color: white;
  border: none;
  padding: 6px 24px;
  border-radius: 5px;
}

.companyDetails {
  width: 95%;
  margin: auto;
}

.companyDetails {
  display: flex;
}

.label b,
.renderData b {
  display: block !important;
  width: 300px;
  padding: 5px;
  font-weight: 450 !important;
}

.companyDetails {
  margin-top: 20px;
}

.dateFilterViewSec {
  padding-bottom: 20px;
  width: 455px !important;
  position: absolute;
  background: white;
  z-index: 9;
  opacity: 2;
  left: 195px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 50px;
  padding-top: 30px;
}

.customWidthForDate {
  width: 368px !important;
  padding: 12px;
}

.customWidthForDate + div .rdrDateRangeWrapper {
  width: 368px !important;
  max-width: 368px !important;
}

.rightBar {
  border-left: 2px solid #f0f2f5;
  height: 100%;
  padding-left: 0.8rem;
}

.orderEditIcon {
  margin-right: 10px !important;
  cursor: pointer;
}

.orderDeleteIcon {
  margin-left: 5px !important;
  cursor: pointer;
}

.customDateFilter {
  position: absolute;
  z-index: 2;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.customDateFilterField input {
  width: 256px;
  height: 22px;
  cursor: pointer;
  background: #ffffff;
  font-size: 12px;
  border-radius: 20px;
}

.customDateFilterField label {
  margin-bottom: 12px !important;
}

.customDateFilterField .MuiOutlinedInput-root.Mui-error {
  border-radius: 8px;
  border: 1px solid rgba(15, 68, 122, 0.5) !important;
}

.customDateFilterField .MuiOutlinedInput-notchedOutline {
  border-radius: 8px;
  border: 1px solid rgba(15, 68, 122, 0.5) !important;
}

.customDateFilterField .MuiOutlinedInput-input {
  padding: 10px 14px 10px 34px !important;
}

.customDateFilterField input::-webkit-input-placeholder {
  color: #a6a6a6 !important;
}

.customDateFilterField input::-moz-placeholder {
  color: #a6a6a6 !important;
}

.customDateFilterField input:-ms-input-placeholder {
  color: #a6a6a6 !important;
}

.customDateFilterField input:-moz-placeholder {
  color: #a6a6a6 !important;
}

.no-drop {
  cursor: not-allowed !important;
}

.driverPhoneField div div {
  width: 294px !important;
}

.mapSec {
  width: 800px !important;
}

.driverPhoneField label {
  top: 8px !important;
}

.driverPhoneField input {
  height: 54px !important;
  box-sizing: border-box;
}

.driverPhoneField div {
  margin-top: 0px !important;
}

.coordinatesField {
  width: 90% !important;
}

.customDateFilter .rdrDefinedRangesWrapper {
  display: none;
}

.customDatePicker .rdrMonth {
  width: 41.8em !important;
}

.customLedgerDatePicker,
.customBookingDatePicker {
  position: absolute;
  z-index: 2;
}

.customLedgerDatePicker .rdrDefinedRangesWrapper,
.customBookingDatePicker .rdrDefinedRangesWrapper {
  display: none;
}

.customBookingDatePicker .rdrMonth {
  width: 33.8em !important;
}

.primaryBtn {
  background: #0c71d7 !important;
  border-radius: 4px;
  color: white !important;
  border: none;
  cursor: pointer;
  font-weight: bold !important;
  padding: 10px 24px !important;
}

.orderManagementStats {
  margin: 30px 0 12px 0;
}

.color-fade {
  color: rgba(0, 0, 0, 0.87) !important;
}

.upload-button {
  width: 170px !important;
}

.mr-10 {
  margin-right: 10% !important;
}

.cursor-move {
  cursor: move !important;
}

.activeColor {
  color: #4b89c8 !important;
}

.tabsBtn {
  cursor: pointer;
  width: 168px !important;
  height: 56px !important;
  background: #ffffff !important;
  border: 1px solid rgba(75, 137, 200, 0.2) !important;
  box-shadow: 4px 4px 5px rgba(75, 137, 200, 0.12) !important;
  border-radius: 8px !important;
}

.createCycleCountBtn {
  cursor: pointer;
  width: 15vw !important;
  height: 50px !important;
}

.icon-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateFields {
  display: flex;
  justify-content: left;
}

.dateField span {
  font-size: 16px;
}

.listingAddBtn {
  min-width: 144px;
  height: 32px;
  background: #4b89c8;
  background-color: #4b89c8 !important;
  border-radius: 8px;
  border: none;
  color: #ffffff !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.0125em !important;
  text-transform: uppercase !important;
}

.customOrderStatsBtn span {
  text-transform: capitalize !important;
}

.normalCase {
  text-transform: capitalize !important;
}

input,
textarea:focus {
  outline: none !important;
}

.orderManagementStatuses {
  margin-bottom: 12px !important;
}

.customOrderStatsBtn:hover {
  background-color: #ffffff !important;
}

.dateFilter img {
  margin-left: auto !important;
}

.filterHeader {
  display: flex;
}

.filterHeader p {
  width: 225px;
  padding: 10px;
  text-align: left;
  padding-left: 40px;
  font-weight: bold;
  font-size: 15px;
}

.filterBody {
  display: flex;
  margin-top: 20px;
  text-align: left;
}

.customFilterField {
  width: 16vw;
  height: 32px;
  background: #ffffff;
  border: 1px solid rgba(15, 68, 122, 0.5);
  border-radius: 8px;
  padding-left: 15px;
}

.customFilterField::-webkit-input-placeholder {
  color: #a6a6a6 !important;
}

.customFilterField::-moz-placeholder {
  color: #a6a6a6 !important;
}

.customFilterField:-ms-input-placeholder {
  color: #a6a6a6 !important;
}

.customFilterField:-moz-placeholder {
  color: #a6a6a6 !important;
}

.customRadio .MuiRadio-colorSecondary.Mui-checked {
  color: #4b89c8 !important;
}

.filterBody span {
  width: 225px;
  padding: 10px;
  padding-left: 40px;
}

.lineBreak {
  width: 86%;
  margin: auto;
  color: lightgray;
  margin-top: -10px;
  margin-left: 34px;
}

.line-break {
  height: 1px;
  color: #e8ecf1;
  background-color: #e8ecf1;
  border: none;
}

.customDrop {
  background: transparent;
  border: none;
}

.customDrop:focus {
  outline: none;
}

.dropDownParent {
  position: relative;
}

.dropDownParent label {
  position: absolute;
}

.dropDownParent select {
  cursor: pointer;
}

.itemRemove div .MuiTreeItem-label .MuiTypography-root {
  opacity: 0 !important;
  font-size: 0px !important;
  transition: 0.2s;
}

#mainItem {
  text-align: left;
  font-size: 14px;
}

.MuiTreeItem-label {
  white-space: nowrap !important;
  overflow: hidden !important;
  padding-left: 10px !important;
  font-size: 16px !important;
  font-style: normal !important;
  letter-spacing: 0.15px !important;
}

.op-4 {
  opacity: 0.4 !important;
}

#mainSidebar {
  background: #0b1940;
  /* box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.20); */
  position: inherit !important;
  /* left: -190px; */
  /* z-index: 0 !important; */
  width: 72px !important;
  transition-delay: 0.2s !important;
}

.grnImagesList div {
  margin-left: 0px !important;
}

#mainSidebar .MuiDrawer-paperAnchorDockedLeft {
  width: 72px !important;
  overflow: hidden !important;
  transition: 0.2s;
}

#mainSidebar .MuiPaper-root {
  width: 72px !important;
  overflow: hidden !important;
  transition: 0.2s;
}

#changeMainLayout {
  padding-left: 0px !important;
  transition: 0.2s;
}

.filterBtn {
  font-size: 16px;
  background: transparent;
  transition: 0.5s;
  color: #2b3a53;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 56px;
  left: -24px;
  margin: auto;
  width: 36px;
  height: 25px;
  opacity: 0;
}

.underline {
  text-decoration: underline !important;
}

.MuiDataGrid-root {
  border: 1px solid rgba(75, 137, 200, 0.2) !important;
  box-shadow: 4px 4px 16px rgb(75 137 200 / 12%) !important;
  border-radius: 8px !important;
}

.MuiDataGrid-columnHeadersInner {
  background-color: #fafafc !important;
}

.MuiDataGrid-columnHeaders {
  background: #fafafc !important;
}

.templateBtn {
  color: white;
  background: #2b3a53;
  border: none;
  float: right;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.radioField {
  float: left;
}

td span {
  color: blue;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.z-index {
  z-index: 2 !important;
}

/* .border {
  height: 1px !important;
  width: 80% !important;
  border-bottom: 1px dashed black !important;
  margin: auto;
  position: relative;
} */

.invoiceBorder {
  height: 1px !important;
  width: 100% !important;
  border-bottom: 1px solid #9da0a6 !important;
  margin: auto;
  position: relative;
}

.cancelDropdown {
  background-color: #f6f8fb;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
  margin-right: 6px;
}

.dropdown-add-action {
  background-color: #0c71d7;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
}

.invoiceBorder {
  height: 1px !important;
  width: 100% !important;
  border-bottom: 1px solid #9da0a6 !important;
  margin: auto;
  position: relative;
}

.cancelDropdown {
  background-color: #f6f8fb;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
  margin-right: 6px;
}

.dropdown-add-action {
  background-color: #0c71d7;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
}

.invoiceBorder {
  height: 1px !important;
  width: 100% !important;
  border-bottom: 1px solid #9da0a6 !important;
  margin: auto;
  position: relative;
}

.cancelDropdown {
  background-color: #f6f8fb;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
  margin-right: 6px;
}

.dropdown-add-action {
  background-color: #0c71d7;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
}

.order-form-tabs {
  height: 20px;
  width: 20px;
  border: 1px solid #4b89c8;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.selected-tab {
  background: #4b89c8 !important;
}

.first-tab {
  top: -18px;
  position: absolute;
}

.second-tab {
  position: absolute;
  top: -18px;
  right: 43%;
}

.driverPhoneToolTip {
  font-size: 14px;
  margin: auto !important;
}

.addRouteMapsDesign {
  width: 100% !important;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 4px 4px 16px rgba(75, 137, 200, 0.12);
  border-radius: 8px;
  padding: 4px 15px 20px 15px !important;
}

.coming-soon-title {
  margin-left: 30px !important;
}

.comming-soon-box {
  box-sizing: border-box;
  width: 86%;
  margin-left: 30px;
  height: 420px;
  line-height: 420px;
  background: #ffffff;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 4px 4px 16px rgba(75, 137, 200, 0.12);
  border-radius: 16px;
}

.third-tab {
  top: -18px;
  position: absolute;
  right: 0;
}

.bg-black {
  background-color: brown !important;
}

.bg-black-solid {
  background-color: black !important;
}

.o-4 {
  opacity: 0.4 !important;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.h-410 {
  height: 410px;
}

.bd-4 {
  border-radius: 4px !important;
}

.ov-auto {
  overflow: auto;
}

.ov-x {
  overflow-x: scroll;
}

.ov-y {
  overflow-y: scroll;
}

.overflow-hidden {
  overflow: hidden !important;
}

.invoiceTitleSection:hover .invoiceTitle {
  display: none;
}

.invoiceTitleSection:hover .invoiceTitleField {
  display: block;
}

.taxField {
  display: block;
  height: 30px;
  width: 90px;
}

.invoiceTitleField {
  display: none;
  height: 30px;
  width: 90px;
  border: 1px solid #0c70d7;
  border-radius: 4px;
  padding: 5px;
  outline: none;
}

.dropdown-menu {
  background: #ffffff;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 0px 4px 13px rgba(11, 62, 114, 0.18);
  border-radius: 8px;
  /* height: 200px; */
}

.listItem {
  padding: 12px 14px;
  list-style-type: none;
}

.invoiceTaxField {
  width: 200px !important;
}

.dropdown-toggle {
  border: 1px solid #0c71d7;
  padding-top: 3px;
  height: 30px;
  width: 150px;
}

.addTax {
  background: #ffffff;
  padding: 12px 14px;
  border: 1px solid rgba(75, 137, 200, 0.2);
  box-shadow: 0px 4px 13px rgba(11, 62, 114, 0.18);
  border-radius: 8px;
}

.action-button {
  width: 100%;
  border: none;
  background-color: #f1f8ff;
  padding: 14px 0;
}

.taxFields {
  height: 25px;
  width: 100%;
}

.invoiceTitleSection {
  width: 100px;
}

.invoiceTaxSection {
  width: 200px !important;
}

.invoiceTitle {
  width: 200px;
  /* margin-top: 8px; */
  font-size: 1rem;
}

.section-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
}

.parentDataTable {
  width: 100%;
  margin-top: 0px;
}

.invoice-form-tabs {
  padding: 10px 24px;
  color: #434343;
  border: 1px solid #a6b8d3;
  border-radius: 20px;
  background: white;
  cursor: pointer;
}

.outlined-tab {
  border: 2px solid #0c70d7 !important;
  color: #0c70d7;
}

.invoice-end-adornment {
  position: absolute;
  top: 0;
  right: 2px;
  bottom: 0;
  align-items: center;
  pointer-events: none;
  padding: 0 2px;
  display: flex;
  opacity: 0;
  transition: opacity 0.2s;
}

.invoice-autocomplete-input {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.invoice-autocomplete-input input {
  height: 30px;
  width: 120px;
  padding: 0px 16px;
  border: none;
  font-size: 1rem;
}

.invoice-dropdown:hover .invoice-end-adornment {
  opacity: 1;
}

.visible {
  opacity: 1;
}

.invoice-dropdown:hover .invoice-autocomplete-input input {
  border: 1px solid #0c71d7;
  border-radius: 4px;
}

.number-input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

#border-visible {
  border: 1px solid #0c71d7;
  outline: none;
}

.css-18lolh3-MuiDataGrid-root {
  border: none !important;
}

.error {
  color: red !important;
}

/* .parentDataTable .MuiDataGrid-main div + div div div div{
  width: 100% !important;
} */
.textField {
  border: none;
  /* border-bottom: 1px solid lightgray !important; */
  height: 24px;
  width: 155px;
  margin-top: 0px;
  padding-left: 12px;
  display: block;
  overflow-x: hidden;
}

#selectCategory {
  border: 1px solid lightgray !important;
  border-radius: 6px;
  margin-top: 11px;
}

#selectCategory label {
  position: absolute;
  top: -6px;
  left: 14px;
  font-size: 17px;
  background: white;
  padding: 0px 8px;
}

#demo-simple-select {
  padding-left: 20px;
}

.w-100 {
  width: 100% !important;
}

.w-100-bold {
  font-weight: 500;
}

.customHeaderIcon {
  /* float: right; */
  margin-top: 22px;
  margin-right: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.datatableActions {
  justify-content: right !important;
}

.datatableCenter div div {
  justify-content: center !important;
}

.datatableLeftHeader {
  justify-content: left !important;
}

.datatableLeftHeader div div {
  justify-content: left !important;
}

.datatableActions div div {
  justify-content: right !important;
}

.driverDatatableActions div div {
  justify-content: right !important;
}

.justify-content-right {
  justify-content: right !important;
}

.embeddReports {
  height: 100% !important;
  width: 100% !important;
}

.customStyleContent div .MuiTableContainer-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.display-sortable {
  visibility: hidden;
}

.routeheader:hover .display-sortable {
  visibility: visible;
}

/* Product InwardId */

.productInward .display-sortable-inwardId {
  visibility: hidden;
}

.productInward:hover .display-sortable-inwardId {
  visibility: visible;
}

.textField:focus-within {
  transition: 0.2s;
  border-bottom: 2px solid blue;
}

.toolbarContainer {
  position: absolute !important;
  top: -64px !important;
}

.dispatchToolbarContainer {
  top: -106px !important;
}

.toolbarContainer button {
  color: white !important;
  font-weight: bold;
  background: #2b3a53;
  padding: 4px 8px;
  margin-left: 7px !important;
}

.mainFilter {
  padding: 7.5px 20px !important;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  margin-left: -4px !important;
  cursor: pointer;
}

.responsiveInventoryBtn {
  padding: 7.5px 20px !important;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  margin-left: -4px !important;
  cursor: pointer;
}

.toolbarContainer button:hover {
  color: white !important;
  font-weight: bold;
  background: #2b3a53;
  padding: 4px 8px;
}

.MuiDataGrid-footerContainer {
  display: none !important;
}

.parentDataTable .MuiDataGrid-footerContainer {
  display: block !important;
}

.textField:focus {
  outline: none;
}

.textField label {
  top: -6px;
  left: 12px;
  background: white;
  padding: 0 6px;
}

.setColField {
  width: 82%;
  margin: auto;
}

.fieldLabel {
  font-weight: bold;
}

.searchFilterBtn {
  text-align: center;
  margin: auto;
}

.closeModalBtn {
  width: 29px;
  position: absolute;
  right: 17px;
  top: 12px;
  font-size: 30px;
  height: 28px;
  border: none;
  background: transparent;
  color: gray;
}

.full-cont {
  width: 100%;
  height: 85px;
}

#removeHoverBtn:hover {
  background-color: #2b3a53 !important;
}

.activeIcon span span span {
  color: white !important;
}

/*Drowpdown CSS*/
.dropdownLabel {
  color: white;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0c71d7;
  border: none;
}

.parentLabel {
  display: flex;
  justify-content: right;
  position: relative;
}

.dropdownLabel .MuiButton-label {
  color: white !important;
  border-radius: 50%;
  padding: 6px 8px;
  background: #2b3a53 !important;
}

.profileDrop {
  list-style-type: none;
  position: absolute;
  right: 18px;
  width: 160px;
  background-color: white;
  /* shadow-elevation-2 rounded-[8px] p-4 bg-white */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 4px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s;
}

.profileDropVisible {
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}

.profileDrop:hover {
  visibility: visible;
  opacity: 1;
}

/*Dropdown CSS*/
.online {
  text-align: center;
  color: green !important;
}

.offline {
  text-align: center;
  color: red !important;
}

#commonSidebar {
  position: inherit !important;
  z-index: 0 !important;
  width: 16vw !important;
}

#parentCommonSidebar .MuiBackdrop-root {
  position: inherit !important;
}

.pl-3 {
  padding-left: 13px !important;
}

.p-0 {
  padding: 0px !important;
}

.parentRouteMaps {
  margin: 0px !important;
}

.parentRouteMaps div {
  padding: 0px !important;
}

/* #commonSidebar div{
    position: inherit !important;
} */
.justify-content-center {
  justify-content: center !important;
}

.justify-content-left {
  justify-content: left !important;
}

.parentLoader {
  background: none;
}

.headerCenter div div div {
  margin: auto;
}

.headerCenter div div p {
  text-align: center;
}

.headerNew {
  background-color: #f6f8fb !important;
}

.filterIconSpan {
  padding-left: 10px;
}

.filterApplied {
  width: 34px;
  height: 34px;
  padding: 6px;
  border-radius: 5px;
  background-color: red !important;
}

.filterIcon {
  width: 34px;
  height: 34px;
  padding: 6px;
  border-radius: 5px;
  background-color: #4b89c8 !important;
}

.newFilterIcon {
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 4px;
  margin-right: -16px;
  background-color: #0c71d7 !important;
}

.newFilterIcon {
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 4px;
  margin-right: -16px;
  background-color: #0c71d7 !important;
}

.newFilterIcon {
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 4px;
  margin-right: -16px;
  background-color: #0c71d7 !important;
}

.loader-container {
  position: absolute;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background: #0000002e;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container2 {
  position: absolute;
  z-index: 0;
  width: 57%;
  height: 70%;
  background: rgba(0, 0, 0, 0) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-grid {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.commentTextArea,
.additionalTextArea {
  padding: 5px 12px !important;
  font-size: 16px !important;
}

::-webkit-input-placeholder {
  color: #a7c1df !important;
}

::-moz-placeholder {
  color: #a7c1df !important;
}

:-ms-input-placeholder {
  color: #a7c1df !important;
}

:-moz-placeholder {
  color: #a7c1df !important;
}

@media print {
  .help-button {
    display: none !important;
  }

  #jsd-widget {
    display: none !important;
  }

  .palletQR {
    page-break-after: always !important;
    padding: 2px !important;
  }
}

@media only screen and (max-width: 1279px) {
  .layoutPadding {
    padding-left: 20rem !important;
  }

  .full-cont {
    width: 90% !important;
    margin-left: auto !important;
  }

  .userToolbarContainer {
    left: 236px;
  }
}

@media only screen and (max-width: 767px) {
  .customPadding {
    padding-left: 0px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  .dateFilterGrid {
    left: auto;
    width: 16vw;
  }
}

iframe[name='JSD widget'] {
  display: block;
  left: 0;
  bottom: 10px;
}

.successText {
  color: #00b050 !important;
}

.darkGrayText {
  color: #616161 !important;
}

p#orangeText {
  background-color: #df8244 !important;
}

.orangeText {
  color: #df8244 !important;
}

.greenText {
  color: #21612e !important;
}

p#greenText {
  background-color: #21612e !important;
}

.yellowText {
  color: #f6c142 !important;
}

p#yellowText {
  background-color: #f6c142 !important;
}

.DarkGreenText {
  color: #4ead5b !important;
}

p#DarkGreenText {
  background-color: #4ead5b !important;
}

.lightGreenText {
  color: #406b29 !important;
}

p#lightGreenText {
  background-color: #ceedd0 !important;
}

.grayText {
  color: #f2f2f2 !important;
}

p#grayText {
  background-color: #f2f2f2 !important;
}

.phyramid-img {
  padding-bottom: 12px;
}

.infomodalimg {
  padding: 22px !important;
  text-align: center;
}

.buttonstyle {
  justify-content: center !important;
}

.rightbutton {
  padding: 6px 30px !important;
}

.order-form-tabs:hover {
  box-shadow: 5px 5px;
  color: lightgray;
}

.active {
  color: #66bb6a !important;
}

.inactive {
  color: #f44336 !important;
}

.warehouseColumnName {
  text-decoration-line: underline;
  color: #4b89c8;
  cursor: pointer;
}

.formikInputField {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 0 5px !important;
  width: 100%;
}

.formikAutocompleteField {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100%;
}

.palletActionAutocomplete > div {
  width: 90% !important;
}

.palletActionFieled > div {
  width: 90% !important;
}

.upload-image-btn {
  width: 100px;
  height: 100px;
  background: #edf3fa;
  border: 1px solid #acc9e6;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-image {
  width: 50px;
  height: 50px;
  margin: 24px;
}

.br-3 {
  border-radius: 8px !important;
}

.image-preview-container {
  width: auto;
  height: 100px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 8px !important;
  position: relative;
  cursor: pointer;
}

.image-preview-doc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.image-preview-container:hover .image-preview-doc {
  opacity: 1 !important;
}

.image-preview {
  width: 100%;
  height: 100%;
}

.textbox .MuiInputBase-root input {
  box-sizing: border-box;
  height: 32px;
  left: 508px;
  top: 712px;
  background: #ffffff;
  border: 1.5px solid #ffffff;
  border-radius: 8px;
}

.tableField div {
  border-radius: 10px !important;
}

.tableField div input {
  padding: 8px 12px !important;
}

.tableField div fieldset {
  border: 2px solid #c1d6ec !important;
}

.tableField {
  width: 100% !important;
}

ul.MuiTreeView-root {
  overflow-y: scroll;
  height: calc(100vh - 160px);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

ul.MuiTreeView-root::-webkit-scrollbar {
  display: none;
}

.detailText {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.0025em;
  color: #000000;
  width: 100%;
}

.online-circle {
  width: 12px;
  height: 12px;
  background: #4ead5b;
  border-radius: 50%;
  margin-right: 5px;
}

.offline-circle {
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 50%;
  margin-right: 5px;
}

.reason-label-print {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reason-label-no-print {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reason-type-color {
  color: black;
  box-sizing: border-box;
  float: right;
  width: 68px;
  padding: 6px 30px 1px 30px;
  border-radius: 48px;
}

.reason-type-color-green {
  background: #c7f7df;
}

.reason-type-color-red {
  background: #f8d7d7;
}

.inputRadius .MuiOutlinedInput-root {
  position: relative;
  border-radius: 10px;
}

/* Route & order CSS */

.routeheader {
  font-weight: bold !important;
}

.orderCalender .dateFilter {
  padding: 6px !important;
  width: 333px;
  height: 40px !important;
}

.statuses {
  margin-top: 10px !important;
}

.generalBtn {
  background-color: transparent !important;
}

.SupportingDocument {
  margin-top: 12px !important;
}

.orderQuantity {
  text-align: center !important;
}

.quantityText {
  text-align: center !important;
}

/* change font size & weight */

.title {
  font-size: 24px;
  font-weight: 600;
}

.subheader {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.margin-left {
  padding-left: 20px;
}
.column {
  float: left;
  width: 50%;
}

@media print {
  #printMe,
  #printMe * {
    visibility: visible;
  }

  #printMe {
    position: absolute;
    left: 0;
    top: 0;
  }

  .print-logo-parent {
    display: block !important;
  }

  .putawayActions {
    display: none !important;
  }

  .mainNavStyle,
  .topBar {
    visibility: hidden;
  }
}

/* GRN product details */

.QR-scanButton {
  display: flex;
}

.QR-scanButton .QRbutton {
  margin-left: 16px;
  cursor: pointer;
}

.ml-auto {
  margin-left: auto !important;
}

.inProgressStatus {
  color: #f6c142;
}

.notAssignedStatus {
  color: #df8244;
}

.completedStatus {
  color: #4ead5b;
}

.w-90 {
  width: 90% !important;
}

#startDate {
  padding: 8px !important;
}

#endDate {
  padding: 8px !important;
}

.driverDetailBtn {
  margin-right: 30px;
}

.vehicleTypeBtn {
  margin-right: 16px;
}

.groupBtn {
  margin-right: 16px;
}

.updateBtn {
  margin-right: 30px;
}

.vendorBtn {
  margin-right: 20px;
}

.viewVendorBtn {
  margin-right: 32px;
}

.iconStyle {
  justify-content: right !important;
}

.userToolbarContainer {
  top: -104px !important;
}

.dockTitle .dockHeading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
  position: relative;
  right: 6px !important;
}

.relocationButtons {
  margin-top: 20px !important;
}

/* Bulk Section Relocation */

.bulkRelocation-scroll {
  width: 100%;
  overflow: auto;
}

.bulkRelocation-scroll > Table {
  width: 100vw;
}

/* Responsive */

/* user screen */
@media only screen and (max-width: 1400px) and (min-width: 1279px) {
  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1400px;
  }

  .makeStyles-gridContainer-35 {
    margin-left: 0 !important;
  }

  /* order management */

  .searchWithFilter {
    width: 500px !important;
  }

  .customPlletizeSearch {
    width: 300px !important;
  }

  #changeMainLayout .searchWithFilter {
    width: 600px !important;
  }

  #changeMainLayout .customPlletizeSearch {
    width: 300px !important;
  }
}

@media only screen and (max-width: 1278px) and (min-width: 1200px) {
  #changeMainLayout .tableDiv {
    width: 100% !important;
    left: 0px !important;
  }

  .userToolbarContainer {
    top: -104px !important;
    left: 0px;
  }

  .filterViewSec {
    left: -120px !important;
  }

  #changeMainLayout .filterViewSec {
    left: -180px !important;
  }

  /* order management */

  .searchWithFilter {
    width: 500px !important;
  }

  .customPlletizeSearch {
    width: 300px !important;
  }

  #changeMainLayout .searchWithFilter {
    width: 600px !important;
  }

  #changeMainLayout .customPlletizeSearch {
    width: 300px !important;
  }

  /* warehouse*/

  .responsive-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px !important;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .bulkRelocation-scroll {
    width: 100%;
    overflow: auto;
  }

  .bulkRelocation-scroll > Table {
    width: 2000px;
  }

  .oNexus-customerOnboarding {
    width: 100%;
    overflow: auto;
  }

  .oNexus-customerOnboarding .onexus-Grid {
    width: 1600px;
  }

  .relocationButtons {
    margin-top: 20px !important;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .customLedgerDatePicker .rdrMonth {
    width: 27.8em !important;
  }

  .customLedgerDatePicker,
  .customBookingDatePicker {
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper,
  .customBookingDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .customBookingDatePicker .rdrMonth {
    width: 34.8em !important;
  }

  .ledgerScroll {
    width: 100%;
    overflow: auto;
  }

  .ledgerScroll > Table {
    width: 1500px;
  }

  .driverDetailBtn {
    margin-right: 18px;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  #changeMainLayout {
    padding-left: 0px !important;
    transition: 0.2s;
  }

  .dividerMargin {
    margin-top: -14px !important;
  }

  .driverPhoneField #companyPhone {
    height: 54px !important;
    padding: 0 0 0 17px !important;
    box-sizing: border-box;
    font-weight: 100 !important;
  }

  /* Add order */
  .searchWithFilter {
    width: 98% !important;
  }

  #changeMainLayout .searchWithFilter {
    width: 95% !important;
  }

  /* Route management */

  .customFilterField {
    width: 98% !important;
  }

  .customFilterViewSec {
    width: 98% !important;
  }

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteListingtable > table {
    width: 1400px !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 1080px !important;
  }

  .routeSummaryBtn {
    margin-right: 20px !important;
  }

  .scroll-RouteSummarytable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteSummarytable > table {
    width: 1080px !important;
  }

  /* warehouse */
  .warehouseDiv {
    width: 95% !important;
    margin-left: 2rem !important;
  }

  .warehouseCalender .rdrCalendarWrapper {
    width: 100% !important;
  }

  .warehouseCalender .rdrDateRangePickerWrapper {
    width: 100% !important;
  }

  .responsive-table {
    overflow: auto;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px !important;
  }

  .updateHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 20px !important;
    letter-spacing: 0.0025em !important;
    color: #000000 !important;
  }

  .embededBtns {
    margin-right: 20px !important;
  }

  .viewVendorBtn {
    margin-right: 16px;
  }

  .filterBody {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 993px) {
  .bulkRelocation-scroll {
    width: 100%;
    overflow: auto;
  }

  .bulkRelocation-scroll > Table {
    width: 2000px;
  }

  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1400px;
  }

  .stockCancelBtn {
    position: relative;
    left: 36%;
  }

  .relocationButtons {
    margin-top: 20px !important;
  }

  .palletScroll-table {
    width: 100%;
    overflow: auto;
  }

  .palletScroll-table > Table {
    width: 1200px;
  }

  .generatedField {
    width: 100% !important;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .inventoryScroll {
    width: 100%;
    overflow: auto;
  }

  .inventoryScroll > Table {
    width: 1300px;
  }

  .customLedgerDatePicker .rdrMonth {
    width: 30.8em !important;
  }

  .customLedgerDatePicker,
  .customBookingDatePicker {
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper,
  .customBookingDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .customBookingDatePicker .rdrMonth {
    width: 35.8em !important;
  }

  .dateFont {
    font-size: 14px !important;
    line-height: 1.9 !important;
  }

  .calenderWidth,
  .orderCustomerSec {
    padding: 10px 3px !important;
  }

  .coordinatesField {
    width: 100% !important;
  }

  .responsive-div {
    margin-left: 0px !important;
  }

  .responsive-mt {
    margin-top: 10px !important;
  }

  .customPadding {
    padding-left: 176px;
    transition: 0.2s;
  }

  .filterViewSect {
    left: 10px;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  #changeMainLayout .tableDiv {
    float: right;
    width: 100% !important;
    position: relative;
    left: 0px;
  }

  #changeMainLayout {
    padding-left: 8px !important;
    transition: 0.2s;
  }

  #currentTitle {
    transition: 0.2s;
  }

  #setCurrentTitleSet {
    margin-left: 74px !important;
    transition: 0.2s;
  }

  .mainFilter {
    margin-left: -10px !important;
  }

  .parentCommonSidebar #currentTitle {
    margin-left: 62px !important;
  }

  .filterViewSec {
    left: -120px !important;
  }

  .closeModalBtn {
    top: 6px !important;
  }

  #changeMainLayout .filterViewSec {
    left: -168px !important;
  }

  .userToolbarContainer {
    left: -1% !important;
  }

  /* order management */

  .searchWithFilter {
    width: 390px !important;
  }

  #changeMainLayout .searchWithFilter {
    width: 490px !important;
  }

  .orderCalender .dateFilter {
    width: 286px !important;
  }

  .makeStyles-buttonsClass-38 {
    position: relative !important;
    right: 10px !important;
  }

  .rdrMonth {
    width: 24em !important;
  }

  .rdrCalendarWrapper {
    width: 97% !important;
  }

  .orderManagementStatuses {
    justify-content: space-between !important;
  }

  .customFilterViewSec {
    width: 88% !important;
  }

  #changeMainLayout .customFilterViewSec {
    width: 92% !important;
  }

  .customFilterField {
    width: 100% !important;
  }

  .customFilterParent b {
    font-size: 14px !important;
  }

  /* Route management */

  .makeStyles-container-24 {
    padding: 10px !important;
  }

  .scroll-table {
    width: 100%;
    overflow: auto;
  }

  .scroll-table > table {
    width: 1200px !important;
  }

  .routeSummaryBtn {
    margin-right: 20px !important;
  }

  /* Route planning */

  .MuiTableCell-root {
    font-size: 10px !important;
  }

  .driverPhoneField #companyPhone {
    height: 54px !important;
    padding: 0 0 0 17px !important;
    box-sizing: border-box;
  }

  #vehicleTypeId-label,
  #vehicleId-label,
  #driverId-label,
  #vendorId-label,
  #costPrice-label,
  #sellingPrice-label {
    font-size: 14px !important;
  }

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteListingtable > table {
    width: 1250px !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 100% !important;
  }

  /* Summary details in create GRN */

  .tableHeader .MuiTableCell-root {
    font-size: 14px !important;
  }

  .detailTable .MuiTableRow-root > td {
    font-size: 12px !important;
  }

  .quantityOrder {
    padding-left: 10px !important;
  }

  /* warehouse screen */

  .detailText {
    font-size: 13px !important;
  }

  .makeStyles-warehouseSearchField-58 .makeStyles-searchBar-59 {
    width: 90% !important;
    margin-left: 16px !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .documentID {
    padding: 10px !important;
  }

  .orderFilter .customFilterViewSec {
    width: 74% !important;
  }

  #changeMainLayout .orderFilter .customFilterViewSec {
    width: 78% !important;
  }

  .warehouseDiv {
    width: 95% !important;
    margin-left: 0rem !important;
  }

  #changeMainLayout .warehouseDiv {
    width: 93% !important;
    margin-left: 2rem !important;
  }

  .responsive-table {
    overflow: auto;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px;
  }
}

.embeddedScroll-table {
  width: 100%;
  overflow: auto;
}

.embeddedScroll-table > Table {
  width: 1200px !important;
}

@media (max-width: 1024px) {
  .warehouseTitle .makeStyles-dialogTitle-58 {
    font-size: 20px !important;
  }

  .warehouseCalender .MuiTypography-body1 {
    font-size: 0.875rem !important;
    line-height: 1.9 !important;
  }

  .warehouseCalender .rdrCalendarWrapper {
    width: 93% !important;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .bulkRelocation-scroll {
    width: 100%;
    overflow: auto;
  }

  .bulkRelocation-scroll > Table {
    width: 2000px;
  }

  .generatedField {
    width: 100%;
  }

  .palletScroll-table {
    width: 100%;
    overflow: auto;
  }

  .palletScroll-table > Table {
    width: 1200px;
  }

  .oNexus-customerOnboarding {
    width: 100%;
    overflow: auto;
  }

  .oNexus-customerOnboarding .onexus-Grid {
    width: 1600px;
  }

  .stockCancelBtn {
    position: relative;
    left: 34%;
  }

  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1400px;
  }

  .relocationButtons {
    margin-top: 30px !important;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .inventoryScroll {
    width: 100%;
    overflow: auto;
  }

  .inventoryScroll > Table {
    width: 1300px;
  }

  .customLedgerDatePicker .rdrMonth {
    width: 27.5em !important;
  }

  .customLedgerDatePicker {
    width: 100% !important;
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .ledgerScroll {
    width: 100%;
    overflow: auto;
  }

  .ledgerScroll > Table {
    width: 1500px;
  }

  .responsiveField {
    margin-top: 0px !important;
  }

  .coordinatesField {
    width: 100% !important;
  }

  .responsive-div {
    margin-left: 0px !important;
  }

  .responsive-mt {
    margin-top: 10px !important;
  }

  .viewVendorBtn {
    margin-right: 16px;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  .driverDetailBtn {
    margin-right: 22px !important;
  }

  #parentCommonSidebar,
  #commonSidebar {
    background: #2b3a53 !important;
    position: inherit !important;
    width: 72px !important;
    transition-delay: 0.2s !important;
  }

  #parentCommonSidebar .MuiPaper-root {
    width: 72px !important;
    overflow: hidden !important;
    transition: 0.2s;
  }

  #mainSidebar {
    width: 225px !important;
    transition: 0.2s !important;
  }

  #mainSidebar #display-none {
    visibility: visible !important;
    opacity: 1;
    transition: 0s;
  }

  #mainSidebar .itemRemove div .MuiTreeItem-label {
    opacity: 1 !important;
    font-size: 14px !important;
    transition: 0s;
  }

  #parentCommonSidebar .makeStyles-desktopDrawer-17 {
    width: 72px !important;
  }

  .MuiSvgIcon-root {
    width: 0.95em !important;
  }

  #mainSidebar .itemRemove a a span span .navChildTitle {
    opacity: 1 !important;
    font-size: 0.75rem !important;
    transition: 0.2s !important;
  }

  .dividerMargin {
    margin-top: -14px !important;
  }

  .activeSearch {
    width: 80px !important;
    padding: 0 7px !important;
  }

  #commonSidebar .MuiBox-root:first-child > div {
    opacity: 0;
    visibility: hidden !important;
    transition: 0.2s;
  }

  .tableDiv {
    float: right;
    width: 100% !important;
    position: relative;
  }

  .filterViewSec {
    left: -130px !important;
    width: 400px !important;
  }

  #changeMainLayout .filterViewSec {
    left: -62px !important;
  }

  .filterHeader p {
    width: 200px !important;
  }

  .search-box {
    left: 43% !important;
  }

  .textField {
    width: 134px;
  }

  .filterBody span {
    width: 190px !important;
    display: flex;
    align-items: center;
  }

  .filterBody {
    font-size: 14px;
  }

  #currentTitle {
    margin-left: -127px !important;
    font-size: 22px !important;
  }

  #setCurrentTitleSet {
    margin-left: 220px !important;
    font-size: 22px !important;
  }

  .makeStyles-pageHeader-29 {
    padding: 16px 8px !important;
  }

  .userToolbarContainer {
    left: -12px !important;
  }

  /* Add order */

  .makeStyles-addNodeBtn-39 {
    width: 54px !important;
    height: 56px !important;
    margin-left: 3px !important;
  }

  .section-heading {
    font-size: 22px !important;
  }

  .makeStyles-params-84 {
    padding: 10px !important;
  }

  #changeMainLayout .makeStyles-params-84 {
    padding: 12px !important;
  }

  .makeStyles-detailText-72 {
    line-height: 45px !important;
  }

  .makeStyles-gridContainer-35 {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  #changeMainLayout .makeStyles-gridContainer-35 {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  #changeMainLayout {
    padding-left: 0px !important;
  }

  .makeStyles-dialogTitle-85 {
    line-height: 25px !important;
  }

  /* Order management screen */

  .orderCalender .dateFilter {
    width: 100% !important;
  }

  #changeMainLayout .rdrMonth {
    width: 40.5em !important;
  }

  #changeMainLayout .rdrCalendarWrapper {
    width: 100% !important;
  }

  .searchWithFilter {
    width: 360px !important;
  }

  .rdrMonth {
    width: 53.5em !important;
  }

  .warehouseCalender .rdrMonth {
    width: 28.8em !important;
  }

  .rdrNextPrevButton {
    background: none !important;
  }

  .rdrCalendarWrapper {
    width: 100% !important;
  }

  .orderManagementStatuses {
    justify-content: space-between !important;
  }

  #changeMainLayout .MuiInput-underline {
    width: 100% !important;
  }

  #changeMainLayout .customFilterParent label b {
    font-size: 10px !important;
  }

  #changeMainLayout .cancelBtn {
    font-size: 10px !important;
  }

  #changeMainLayout .submitBtns {
    font-size: 10px !important;
  }

  .icon-align-center {
    font-size: 14px !important;
  }

  #task-management-button .icon-align-center {
    font-size: 8px !important;
  }

  .listingAddBtn {
    height: 35px !important;
  }

  .scroll-table {
    width: 100%;
    overflow: auto;
  }

  .scroll-table > table {
    width: 1200px !important;
  }

  .user-input #companyPhone {
    width: 100% !important;
    font-weight: 100 !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 1000px !important;
  }

  /* route management */

  .customFilterViewSec {
    width: 85% !important;
  }

  .customFilterField {
    width: 100% !important;
    font-size: 12px !important;
  }

  #changeMainLayout .customFilterField {
    font-size: 10px !important;
  }

  .customFilterParent label b {
    font-size: 14px !important;
  }

  .driverPhoneField #companyPhone {
    height: 54px !important;
    padding: 0 0 0 17px !important;
    box-sizing: border-box;
    font-weight: 100 !important;
  }

  .rowSpacing {
    row-gap: 10px !important;
  }

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteListingtable > table {
    width: 1260px !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .scroll-RouteSummarytable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteSummarytable > table {
    width: 1000px !important;
  }

  .routeSummaryBtn {
    margin-right: 20px !important;
  }

  #changeMainLayout .customFilterViewSec {
    width: 100% !important;
  }

  .orderFilter .customFilterViewSec {
    width: 72% !important;
  }

  #changeMainLayout .orderFilter .customFilterParent label b {
    font-size: 14px !important;
  }

  .orderCalenderPicker .rdrCalendarWrapper {
    width: 100% !important;
  }

  .orderCalenderPicker .rdrMonth {
    width: 29.5em !important;
  }

  #changeMainLayout .orderCalenderPicker .rdrMonth {
    width: 24.5em !important;
  }

  /* warehouse */

  .warehouseCalender .rdrCalendarWrapper {
    width: 100% !important;
  }

  .warehouseCalender .rdrDateRangePickerWrapper {
    width: 100% !important;
  }

  #changeMainLayout .warehouseCalender .rdrMonth {
    width: 24.5em !important;
  }

  .responsive-table {
    width: 100%;
    overflow: auto;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px;
  }

  .updateHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 20px !important;
    letter-spacing: 0.0025em !important;
    color: #000000 !important;
  }

  .embeddedScroll-table {
    width: 100%;
    overflow: auto;
  }

  .embeddedScroll-table > Table {
    width: 1000px !important;
  }

  .embededBtns {
    margin-right: 20px !important;
  }

  .filterViewSect {
    top: 12px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 578px) {
  .bulkRelocation-scroll {
    width: 100%;
    overflow: auto;
  }

  .bulkRelocation-scroll > Table {
    width: 2000px;
  }

  .baysScroll-table {
    width: 100%;
    overflow: auto;
  }

  .baysScroll-table > Table {
    width: 550px;
  }

  .generatedField {
    width: 100%;
  }

  .oNexus-customerOnboarding {
    width: 100%;
    overflow: auto;
  }

  .oNexus-customerOnboarding .onexus-Grid {
    width: 1600px;
  }

  .stockCancelBtn {
    position: relative;
    left: 18%;
  }

  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1400px;
  }

  .relocationButtons {
    margin-top: 30px !important;
  }

  #changeMainLayout .responsiveBulkBtn {
    margin-top: 6px !important;
    padding: 4px 7px !important;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .customLedgerDatePicker .rdrMonth {
    width: 23.8em !important;
  }

  .customLedgerDatePicker,
  .customBookingDatePicker {
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper,
  .customBookingDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .customBookingDatePicker .rdrMonth {
    width: 37.5em !important;
  }

  .ledgerScroll {
    width: 100%;
    overflow: auto;
  }

  .ledgerScroll > Table {
    width: 1500px;
  }

  .responsiveField {
    margin-top: 0px !important;
  }

  .coordinatesField {
    width: 100% !important;
  }

  .responsive-div {
    margin-left: 0px !important;
  }

  .responsive-mt {
    margin-top: 10px !important;
  }

  .filterBody {
    font-size: 14px;
  }

  .viewVendorBtn {
    margin-right: 18px;
  }

  .driverDetailBtn {
    margin-right: 22px;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  #parentCommonSidebar,
  #commonSidebar {
    background: #2b3a53 !important;
    position: inherit !important;
    width: 72px !important;
    transition-delay: 0.2s !important;
  }

  #parentCommonSidebar .MuiPaper-root {
    width: 72px !important;
    overflow: hidden !important;
    transition: 0.2s;
  }

  #mainSidebar {
    width: 246px !important;
    transition: 0.2s !important;
  }

  .dividerMargin {
    margin-top: -12px !important;
  }

  .topBar .MuiGrid-item.MuiGrid-grid-xs-8 {
    padding-left: 192px;
    transition: 0.2s;
  }

  .customPadding {
    padding-left: 0 !important;
    transition: 0s;
  }

  .makeStyles-pageHeader-29 {
    padding: 16px 4px !important;
  }

  .tableDiv {
    width: 100% !important;
    left: -10px !important;
  }

  #changeMainLayout .tableDiv {
    width: 100% !important;
    left: -8px !important;
  }

  .userToolbarContainer {
    left: -2px !important;
  }

  .MuiAppBar-positionFixed {
    top: 4px !important;
  }

  #setCurrentTitleSet {
    margin-left: 46px !important;
    font-size: 20px !important;
  }

  #changeMainLayout {
    padding-left: 0px !important;
    transition: 0.2s;
  }

  #commonSidebar .MuiBox-root:first-child > div {
    opacity: 0;
    visibility: hidden !important;
    transition: 0.2s;
  }

  #mainSidebar #display-none {
    visibility: visible !important;
    opacity: 1;
    transition: 0s;
  }

  #mainSidebar .itemRemove div .MuiTreeItem-label {
    opacity: 1 !important;
    font-size: 1rem !important;
    transition: 0s;
  }

  #parentCommonSidebar .makeStyles-desktopDrawer-17 {
    width: 72px !important;
  }

  .filterViewSec {
    width: 350px !important;
    left: -92px !important;
  }

  .filterHeader p {
    width: 172px !important;
  }

  .filterBody span {
    width: 165px !important;
  }

  .textField {
    width: 124px;
  }

  #mainSidebar .MuiDrawer-paperAnchorDockedLeft {
    width: 225px !important;
    overflow: hidden !important;
    transition: 0.2s;
  }

  .parentLabel {
    right: 0px !important;
    top: 0px !important;
  }

  .css-r11z79-MuiDataGrid-root {
    font-size: 0.75rem !important;
  }

  .makeStyles-container-23 {
    padding: 20px 6px !important;
  }

  .MuiSvgIcon-root {
    width: 0.85em !important;
  }

  .search-box {
    left: 40%;
  }

  .activeSearch {
    width: 60% !important;
  }

  .search-box:hover .search-input {
    width: 89px;
    padding: 0 7px;
  }

  #changeMainLayout .filterViewSec {
    left: -67px !important;
  }

  #changeMainLayout .search-box {
    left: 38% !important;
  }

  #mainSidebar .itemRemove a a span span .navChildTitle {
    opacity: 1 !important;
    font-size: 0.75rem !important;
    transition: 0.2s !important;
  }

  .css-1h0lv03 {
    position: relative;
    right: 8px;
  }

  /* Add order */

  .makeStyles-gridContainer-35 {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  #changeMainLayout .makeStyles-addNodeBtn-39 {
    width: 86% !important;
  }

  #changeMainLayout .makeStyles-timePicker-41 {
    width: 87% !important;
  }

  .orderSummaryContainer .makeStyles-dialogTitle-75 {
    font-size: 20px !important;
  }

  .section-heading {
    font-size: 20px !important;
  }

  #changeMainLayout .orderSummaryContainer .makeStyles-dialogTitle-75 {
    font-size: 16px !important;
  }

  .lineSpace {
    margin: 10px 0 !important;
  }

  .makeStyles-detailText-72 {
    font-size: 14px !important;
    line-height: 28px !important;
  }

  #changeMainLayout .makeStyles-mainContainer-75 {
    padding: 10px 2px !important;
  }

  .makeStyles-timePicker-41 {
    width: 87% !important;
  }

  .orderSummaryContainer .makeStyles-dialogTitle-85 {
    font-size: 20px !important;
  }

  #changeMainLayout .makeStyles-secAddress-52 {
    margin-left: 0px !important;
  }

  .makeStyles-footerGrid-44 {
    width: 100% !important;
    height: 122px !important;
  }

  .makeStyles-footerAddBtn-49 {
    width: 40px !important;
    height: 40px !important;
  }

  .makeStyles-footerGridItem-46 {
    text-align: left !important;
    padding-left: 4px;
  }

  #changeMainLayout .makeStyles-footerAddBtn-49 {
    width: 32px !important;
    height: 35px !important;
  }

  /* Order management */

  .orderCalender .dateFilter {
    width: 100% !important;
  }

  .rdrMonth {
    width: 37.667em !important;
  }

  #changeMainLayout .rdrMonth {
    width: 22.667em !important;
  }

  #changeMainLayout .rdrCalendarWrapper {
    width: 94% !important;
  }

  .searchWithFilter {
    width: 452px !important;
    margin-bottom: 10px !important;
  }

  #changeMainLayout .searchWithFilter {
    width: 278px !important;
  }

  .exportCustomBtn {
    width: 126px !important;
  }

  /* route management */

  .MuiInput-underline {
    width: 100% !important;
  }

  #changeMainLayout .MuiInput-underline {
    width: 100% !important;
  }

  .customFilterViewSec {
    width: 100% !important;
  }

  .customFilterField {
    width: 100% !important;
    font-size: 12px !important;
  }

  #changeMainLayout .customFilterField {
    font-size: 10px !important;
  }

  .customFilterParent label b {
    font-size: 14px !important;
  }

  #changeMainLayout .customFilterParent label b {
    font-size: 10px !important;
  }

  #changeMainLayout .cancelBtn {
    font-size: 10px !important;
  }

  #changeMainLayout .submitBtns {
    font-size: 10px !important;
  }

  .icon-align-center {
    font-size: 14px !important;
  }

  .listingAddBtn {
    height: 34px !important;
  }

  .cancelBtn {
    font-size: 12px !important;
    width: 50% !important;
  }

  .submitBtns {
    font-size: 12px !important;
    width: 50% !important;
  }

  .scroll-table {
    width: 100%;
    overflow: auto;
  }

  .scroll-table > table {
    width: 1200px !important;
  }

  .customFilterViewSec .justify-content-right {
    justify-content: left !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 1000px !important;
  }

  .driverPhoneField #companyPhone {
    height: 54px !important;
    padding: 0 0 0 17px !important;
    box-sizing: border-box;
    font-weight: 100 !important;
  }

  .user-input #companyPhone {
    width: 100% !important;
    padding: 22px 8px !important;
    font-weight: 100 !important;
  }

  .scrollGRN-table {
    width: 100%;
    overflow: auto;
  }

  .scrollGRN-table > table {
    width: 500px !important;
  }

  .btnGroup {
    text-align: left !important;
  }

  #titleGRN .MuiBox-root-13 {
    margin-right: 0px !important;
  }

  #titleGRN #setCurrentTitleSet {
    font-size: 14px !important;
  }

  /* Route Management */

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .routeStatus {
    text-align: left !important;
    margin-top: 10px !important;
  }

  .scroll-RouteListingtable > table {
    width: 1260px !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  #greenText > svg,
  #yellowText > svg,
  #orangeText > svg {
    margin-top: 2px !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .scroll-RouteSummarytable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteSummarytable > table {
    width: 1000px !important;
  }

  .routeSummaryBtn {
    margin-right: 20px !important;
  }

  .orderCustomerBtn {
    justify-content: space-between !important;
  }

  .orderCustomerBtn #customers-label {
    font-size: 12px !important;
    top: -6px !important;
  }

  .orderCalenderPicker .rdrCalendarWrapper {
    width: 100% !important;
  }

  .orderCalenderPicker .rdrMonth {
    width: 22.5em !important;
  }

  #changeMainLayout .orderCalenderPicker .rdrMonth {
    width: 37.5em !important;
  }

  .orderCalenderPicker .customDateFilter {
    width: 100% !important;
  }

  .rdrNextButton i {
    margin: 0 0 0 2px !important;
  }

  #changeMainLayout .orderCalenderPicker {
    flex-direction: column !important;
  }

  #changeMainLayout .orderCalenderPicker .orderCalender {
    width: 100% !important;
    max-width: 448px !important;
  }

  #changeMainLayout .orderCalenderPicker .rdrCalendarWrapper {
    width: 100% !important;
  }

  #changeMainLayout .rdrNextButton i {
    margin: 0 0 0 6px !important;
  }

  .vehicleId {
    margin-top: 20px !important;
  }

  /* warehouse */

  .warehouseDiv {
    width: 94% !important;
    margin-left: 1rem !important;
  }

  #changeMainLayout .warehouseDiv {
    width: 94% !important;
    margin-left: 0rem !important;
  }

  .warehouseCalender .rdrCalendarWrapper {
    width: 100% !important;
  }

  .warehouseCalender .rdrDateRangePickerWrapper {
    width: 100% !important;
  }

  .warehouseCalender .rdrMonth {
    padding: 0 3.833em 1.666em 0.833em !important;
  }

  #changeMainLayout .warehouseCalender .rdrMonth {
    padding: 0 0.833em 1.666em 0.833em !important;
    width: 24.667em !important;
  }

  #changeMainLayout .warehouseCalender .rdrCalendarWrapper,
  #changeMainLayout .warehouseCalender .rdrDateRangePickerWrapper {
    width: 100% !important;
  }

  .responsive-table {
    width: 100%;
    overflow: auto;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px;
  }

  .updateHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 20px !important;
    letter-spacing: 0.0025em !important;
    color: #000000 !important;
  }

  .roleContainer {
    width: 50% !important;
  }

  .embeddedScroll-table {
    width: 100%;
    overflow: auto;
  }

  .embeddedScroll-table > Table {
    width: 900px !important;
  }

  .embededBtns {
    margin-right: 20px !important;
  }

  .filterViewSect {
    top: 12px;
  }

  .mainFilter {
    position: relative;
    left: -8px;
  }

  .vehicleType {
    margin: 0px -8px !important;
  }
}

@media only screen and (max-width: 578px) and (min-width: 360px) {
  .bulkRelocation-scroll {
    width: 100%;
    overflow: auto;
  }

  .bulkRelocation-scroll > Table {
    width: 2000px;
  }

  .palletActionAutocomplete > div,
  .palletActionFieled > div {
    width: 100% !important;
  }

  .baysScroll-table {
    width: 100%;
    overflow: auto;
  }

  .baysScroll-table > Table {
    width: 550px;
  }

  .responsiveBulkBtn {
    padding: 5px 8px !important;
  }

  .oNexus-customerOnboarding {
    width: 100%;
    overflow: auto;
  }

  .oNexus-customerOnboarding .onexus-Grid {
    width: 1600px;
  }

  .stockCancelBtn {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1400px;
  }

  .bookingCapacity {
    margin-left: 0px !important;
  }

  .headingBooking {
    margin-top: 10px !important;
    margin-left: 0px !important;
  }

  .bookingDialogContent {
    padding: 20px 16px !important;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .inventoryScroll {
    width: 100%;
    overflow: auto;
  }

  .inventoryScroll > Table {
    width: 1300px;
  }

  .responsiveInventoryBtn {
    padding: 5px 20px !important;
    position: relative;
    left: 4px;
  }

  .customLedgerDatePicker,
  .customBookingDatePicker {
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper,
  .customBookingDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .customBookingDatePicker .rdrMonth {
    width: 90% !important;
  }

  .ledgerScroll {
    width: 100%;
    overflow: auto;
  }

  .ledgerScroll > Table {
    width: 1500px;
  }

  .responsiveCompanyField {
    margin-top: 0px !important;
  }

  .coordinatesField {
    width: 100% !important;
  }

  .responsive-div {
    margin-left: 0px !important;
  }

  .orderCustomerSec {
    margin-top: 10px !important;
  }

  .orderCustomerSec div div label {
    top: -4px !important;
  }

  .customOrderStatsBtn span {
    font-size: 13px;
  }

  .statsWidth {
    width: 100% !important;
  }

  .upload-button {
    margin-top: 10px !important;
    margin-left: 6px !important;
  }

  .aisleBtn {
    margin-top: 10px !important;
  }

  .aislesTab {
    flex-direction: column;
    width: 284px !important;
  }

  .aislesTab > .css-l3nkxa {
    width: 95% !important;
  }

  .companyDetails {
    width: 100%;
    padding-left: 2px;
  }

  .vehicleView {
    margin: 0px -8px !important;
  }

  .vehicleButtons {
    margin-top: 6px !important;
  }

  .vehicleCloseBtn {
    margin-left: 8px !important;
  }

  .driverDetailBtn {
    margin-right: 12px;
  }

  .viewVendorBtn {
    margin-right: 12px;
  }

  .vendorBtn {
    margin-right: 10px;
  }

  .orderFilter-heading {
    font-size: 14px !important;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  .filterViewSect {
    top: 0px;
  }

  #parentCommonSidebar,
  #commonSidebar {
    background: #2b3a53 !important;
    position: inherit !important;
    width: 72px !important;
    transition-delay: 0.2s !important;
  }

  #parentCommonSidebar .MuiPaper-root {
    width: 72px !important;
    overflow: hidden !important;
    transition: 0.2s;
  }

  #mainSidebar #display-none {
    visibility: visible !important;
    opacity: 1;
    transition: 0s;
  }

  #parentCommonSidebar .makeStyles-desktopDrawer-17 {
    width: 72px !important;
  }

  #changeMainLayout .makeStyles-addUserButton-27 {
    left: 6px !important;
  }

  .MuiSvgIcon-root {
    width: 0.95em !important;
  }

  #mainSidebar .itemRemove a a span span .navChildTitle {
    opacity: 1 !important;
    font-size: 0.95rem !important;
    transition: 0.2s !important;
  }

  .dividerMargin {
    margin-top: -20px !important;
  }

  .activeSearch {
    width: 80px !important;
    padding: 0 7px !important;
  }

  #commonSidebar .MuiBox-root:first-child > div {
    opacity: 0;
    visibility: hidden !important;
    transition: 0.2s;
  }

  #currentTitle {
    font-size: 16px !important;
    white-space: break-spaces !important;
    margin-bottom: 10px !important;
  }

  #setCurrentTitleSet {
    margin-left: 22px !important;
  }

  .search-box {
    left: 35% !important;
    width: 100% !important;
  }

  .parentLabel {
    top: 0px;
  }

  .groupBtn .MuiButton-containedPrimary,
  .MuiButton-contained {
    font-size: 0.6rem !important;
  }

  .searchIcons {
    font-size: 22px !important;
  }

  .mainFilter {
    padding: 5.5px 20px !important;
    font-size: 12px !important;
  }

  #mainSidebar .itemRemove div .MuiTreeItem-label {
    font-size: 0.88rem !important;
    opacity: 1 !important;
    transition: 0s;
  }

  .search-btn {
    position: absolute;
    right: 0;
  }

  .filterViewSec {
    width: 273px !important;
    left: -52px !important;
  }

  .closeModalBtn {
    top: 8px;
    width: 12px;
    font-size: 22px;
  }

  .filterHeader p {
    width: 126px !important;
  }

  .filterBody span {
    width: 126px !important;
    padding-left: 32px;
  }

  .textField {
    width: 86px !important;
    font-size: 12px !important;
  }

  .filterBody {
    font-size: 12px !important;
    align-items: baseline !important;
  }

  #mainSidebar {
    width: 360px !important;
  }

  #mainSidebar .MuiDrawer-paperAnchorDockedLeft {
    width: 360px !important;
  }

  .userToolbarContainer {
    left: -6px !important;
    top: -106px !important;
  }

  /* Add order */

  .makeStyles-addNodeBtn-39 {
    width: 30px !important;
    height: 45px !important;
  }

  .makeStyles-cancelBtn-38 {
    font-size: 10px !important;
  }

  .makeStyles-submitBtn-37 {
    font-size: 10px !important;
  }

  .referenceField {
    width: 80% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .upload-image-btn {
    height: 80px !important;
    margin-left: 2px !important;
    margin-top: 2px !important;
  }

  .image-preview-container {
    height: 80px !important;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent .makeStyles-detailText-72 {
    font-size: 12px !important;
  }

  .section-heading {
    font-size: 18px !important;
  }

  .radioForm {
    width: 100% !important;
  }

  .tableHeader .MuiTableCell-root {
    font-size: 0.84rem !important;
    line-height: 1.2rem !important;
  }

  .uomCenter {
    text-align: center !important;
  }

  .detailTable .MuiTableCell-root {
    padding: 8px !important;
    font-size: 0.65rem;
  }

  #moveType-label,
  #customer-label,
  #pickupId-label,
  #dropOffId-label {
    font-size: 0.85rem !important;
    top: -3px !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 4px 9px !important;
  }

  .MuiTypography-colorError {
    font-size: 0.85rem !important;
  }

  .makeStyles-dialogTitle-89 {
    font-size: 24px !important;
  }

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 6px !important;
  }

  .makeStyles-detailText-72 {
    line-height: 26px !important;
  }

  .makeStyles-addNodeBtn-78 {
    width: 30px !important;
    height: 44px !important;
  }

  .notificationBtn {
    padding: 5px 35px !important;
    border-radius: 4px !important;
  }

  .makeStyles-placeInput-145 {
    width: 96% !important;
  }

  .exportCustomBtn {
    width: 100px !important;
  }

  .dateFilter .MuiTypography-body1 {
    font-size: 0.82rem !important;
    line-height: 2 !important;
  }

  .orderCalender .dateFilter {
    width: 248px !important;
  }

  .rdrCalendarWrapper {
    width: 100% !important;
  }

  .customDateFilter {
    width: 246px !important;
  }

  .rdrMonthAndYearWrapper {
    justify-content: space-around !important;
  }

  .rdrMonth {
    width: 20em !important;
  }

  .rdrNextPrevButton {
    background: none !important;
  }

  .rdrDateDisplayWrapper {
    width: 246px !important;
  }

  .rdrMonthAndYearPickers select {
    padding: 10px 19px 10px 10px !important;
  }

  .icon-align-center {
    font-size: 11px !important;
  }

  .searchWithFilter {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .scroll-table {
    width: 100%;
    overflow: auto;
  }

  .scroll-table > table {
    width: 1200px !important;
  }

  .customOrderStatsBtn {
    padding: 6px 14px !important;
  }

  .customFilterViewSec {
    width: 100% !important;
  }

  .customFilterField {
    width: 100% !important;
  }

  .customFilterViewSec .justify-content-right {
    flex-direction: column !important;
  }

  .cancelBtn {
    width: 100% !important;
  }

  .submitBtns {
    width: 100% !important;
  }

  .customFilterViewSec .ml-3 {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }

  .driverPhoneField #companyPhone {
    height: 54px !important;
    padding: 0 0 0 17px !important;
    box-sizing: border-box;
    font-weight: 100 !important;
    width: 100% !important;
  }

  .cancelRouteBtn {
    width: 100% !important;
  }

  .routeUpdateButton .submitBtn {
    width: 100% !important;
  }

  .vehicleId {
    margin-top: 20px !important;
  }

  .submitBtn {
    font-size: 11px !important;
  }

  .makeStyles-routeAutocomplete-24 {
    width: 100% !important;
  }

  .routeOrder {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 835px !important;
  }

  .toolbarContainer button {
    margin-left: -2px !important;
  }

  .idMargin {
    margin-top: 10px !important;
  }

  .scrollGRN-table {
    width: 100%;
    overflow: auto;
  }

  .scrollGRN-table > table {
    width: 350px !important;
  }

  .btnGroup {
    text-align: left !important;
  }

  /* Route Management */
  .listingAddBtn {
    width: 100% !important;
  }

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteListingtable > table {
    width: 1260px !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  #greenText > svg,
  #yellowText > svg,
  #orangeText > svg {
    margin-top: 0px !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .tabsBtn {
    width: 228px !important;
  }

  .routeUpdateButton {
    text-align: left !important;
    margin-top: 15px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100% !important;
  }

  #statusFont {
    font-size: 18px !important;
  }

  .routeStatus {
    text-align: left !important;
    margin-top: 10px !important;
  }

  .download-image {
    margin: 16px !important;
  }

  .scroll-RouteSummarytable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteSummarytable > table {
    width: 1000px !important;
  }

  .routeSummaryBtn {
    margin-right: 20px !important;
  }

  #titleGRN #currentTitle {
    margin-left: calc(16vw + 18px) !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .addOrderTable {
    width: 100% !important;
    overflow: auto;
  }

  .addOrderTable .addOrder-tableGrid {
    width: 850px !important;
  }

  .vendorField {
    margin-top: 20px !important;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent .columnCountry {
    overflow: auto !important;
  }

  /* warehouse*/

  .responsive-table {
    width: 100%;
    overflow: auto;
  }

  .responsive-table .MuiDataGrid-root {
    width: 1200px;
  }

  .updateHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 20px !important;
    letter-spacing: 0.0025em !important;
    color: #000000 !important;
    position: relative;
    right: 10px;
  }

  .imageBar {
    width: 100%;
    overflow: auto;
  }

  .warehouseDiv {
    width: 95% !important;
    padding-left: 1.5rem !important;
  }

  .warehouseCalender .rdrCalendarWrapper {
    width: 100% !important;
  }

  .warehouseCalender .dateFilter .MuiTypography-body1 {
    font-size: 10px !important;
    line-height: 2.5 !important;
  }

  .warehouseTitle .spanWarehouse {
    flex-direction: column;
    font-size: 11px !important;
  }

  .warehouseTabs {
    width: 270px !important;
  }

  .warehouseCompanyDetails .label b {
    display: block !important;
    width: 165px;
    padding: 5px;
    font-weight: 450 !important;
    font-size: 12px !important;
  }

  .warehouseCompanyDetails .renderData b {
    display: block !important;
    width: 130px;
    padding: 5px;
    font-weight: 450 !important;
    font-size: 12px !important;
  }

  .doctTable-responsive {
    width: 100%;
    overflow: auto;
  }

  .dockTitle .makeStyles-dialogTitle-65 {
    font-size: 24px !important;
    position: relative;
    right: 10px;
  }

  .dockWarehouseBtns {
    flex-direction: column;
    row-gap: 10px;
    position: relative;
    right: 4px;
  }

  .roleContainer {
    width: 65% !important;
  }

  .iconWidth {
    width: 100% !important;
  }

  .nameField {
    margin-top: 20px !important;
  }

  .embeddedScroll-table {
    width: 100%;
    overflow: auto;
  }

  .embeddedScroll-table > Table {
    width: 700px !important;
  }

  .embededBtns {
    justify-content: center !important;
  }

  .footerGrid-addOrder {
    height: 235px !important;
  }

  .imagePreviewSec {
    width: 110px !important;
  }

  .vehicleType {
    margin: 0px -8px !important;
  }

  .vehicleTypeBtn {
    flex-direction: column;
    margin-left: 6px !important;
    padding: 12px !important;
  }

  .vehicleTypeBtn > Button {
    width: 100%;
    margin-bottom: 10px;
  }

  .vehicleCancelBtn {
    margin-left: 8px !important;
  }
}

@media (max-width: 1000px) {
  .dropdownLabel {
    width: 35px !important;
    height: 35px !important;
    padding: 4px !important;
  }

  .preventOverflow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.scrollGRN-table {
  width: 100% !important;
}

@media (max-width: 959px) {
  .driverId {
    margin-top: 20px !important;
  }

  .scroll-Routetable {
    width: 100%;
  }
}

.scroll-Routetable {
  width: 100%;
}

@media (max-width: 600px) {
  .vehicleNumber {
    margin-top: 20px !important;
  }
}

.inventoryScroll {
  width: 100% !important;
  overflow: auto !important;
}

.inventoryScroll > Table {
  width: 1300px !important;
}

@media (min-width: 1400px) {
  .inventoryScroll {
    width: 100% !important;
    overflow: auto !important;
  }

  .inventoryScroll > Table {
    width: 1300px !important;
  }

  .scroll-RouteListingtable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteListingtable > table {
    width: 100% !important;
  }

  .statusRoute {
    white-space: normal !important;
  }

  .routeTableBorder {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 1200px !important;
  }

  .embeddedScroll-table {
    width: 100%;
    overflow: auto;
  }

  .embeddedScroll-table > Table {
    width: 100% !important;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .oNexus-customerOnboarding {
    width: 100%;
    overflow: auto;
  }

  .oNexus-customerOnboarding .onexus-Grid {
    width: 1600px;
  }

  .stockMovement-scroll {
    width: 100%;
    overflow: auto;
  }

  .stockMovement-scroll > Table {
    width: 1150px;
  }

  .stockCancelBtn {
    position: relative;
    left: 22%;
  }

  .relocationButtons {
    margin-top: 30px !important;
  }

  #currentTitle {
    transition: 0.2s;
    font-size: 22px;
  }

  .warehouseScroll-table {
    width: 100% !important;
    overflow: auto !important;
  }

  .warehouseScroll-table .bookingGrid {
    width: 1500px !important;
  }

  .responsiveInventoryBtn {
    position: relative;
    right: 0px !important;
  }

  .customLedgerDatePicker .rdrMonth {
    width: 23.8em !important;
  }

  .customLedgerDatePicker {
    position: absolute;
    z-index: 2;
  }

  .customLedgerDatePicker .rdrDefinedRangesWrapper {
    display: none;
  }

  .calenderWidth {
    margin-top: -3px !important;
  }

  .ledgerScroll {
    width: 100%;
    overflow: auto;
  }

  .ledgerScroll > Table {
    width: 1500px;
  }

  .responsiveField {
    margin-top: 0px !important;
  }

  .coordinatesField {
    width: 100% !important;
  }

  .responsive-div {
    margin-left: 0px !important;
  }

  .responsive-mt {
    margin-top: 10px !important;
  }

  .customPadding {
    padding-left: 192px;
    transition: 0.2s;
  }

  .driverDetailBtn {
    margin-right: 22px;
  }

  .mainFilter {
    position: relative !important;
    right: 10px !important;
  }

  .bulkBtns {
    margin-top: 10px !important;
  }

  .embededBtns {
    margin-right: 20px !important;
  }

  .search-box {
    left: 40% !important;
  }

  .dividerMargin {
    margin-top: -12px;
  }

  .scroll-Routetable {
    width: 100%;
    overflow: auto;
  }

  .scroll-Routetable > table {
    width: 100% !important;
  }

  .scroll-RouteSummarytable {
    width: 100%;
    overflow: auto;
  }

  .scroll-RouteSummarytable > table {
    width: 1000px !important;
  }

  .scroll-RouteSummarytable .MuiTableCell-head {
    padding: 16px 4px !important;
  }

  .phone {
    font-size: 12px !important;
  }

  .customFilterViewSec {
    width: 100% !important;
  }

  #changeMainLayout .customFilterViewSec {
    width: 92% !important;
  }

  .routeSearch .searchWithFilter {
    width: 357px !important;
  }

  #changeMainLayout .routeSearch .searchWithFilter {
    width: 410px !important;
  }

  .orderFilter .customFilterViewSec {
    width: 92% !important;
  }

  #changeMainLayout .orderFilter .customFilterViewSec {
    width: 92% !important;
  }

  .formGrn {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  .updateHeading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 20px !important;
    letter-spacing: 0.0025em !important;
    color: #000000 !important;
  }

  .embeddedScroll-table {
    width: 100%;
    overflow: auto;
  }

  .embeddedScroll-table > Table {
    width: 1200px !important;
  }

  #sectionRelocationTable .table-cell-trucate {
    overflow: auto !important;
  }

  #sectionRelocationTable .table-cell-trucate::-webkit-scrollbar {
    display: none !important;
  }

  .filterViewSect {
    left: 45px;
  }

  #changeMainLayout .filterViewSect {
    left: 0 !important;
  }

  .filterBody {
    font-size: 14px;
  }
}

@media only screen and (max-width: 360px) and (min-width: 320px) {
  #parentCommonSidebar,
  #commonSidebar {
    background: #2b3a53 !important;
    position: inherit !important;
    width: 57px !important;
    transition-delay: 0.2s !important;
  }

  #parentCommonSidebar .MuiPaper-root {
    width: 57px !important;
    overflow: hidden !important;
    transition: 0.2s;
  }

  #mainSidebar #display-none {
    visibility: visible !important;
    opacity: 1;
    transition: 0s;
  }

  #mainSidebar .itemRemove div .MuiTreeItem-label {
    opacity: 1 !important;
    font-size: 14px !important;
    transition: 0s;
  }

  .css-19midj6,
  .css-1h0lv03 {
    padding: 16px 8px !important;
  }

  #parentCommonSidebar .css-19midj6,
  #parentCommonSidebar .css-1h0lv03 {
    padding: 16px 6px !important;
  }

  #mainSidebar .itemRemove a a span span .navChildTitle {
    opacity: 1 !important;
    font-size: 0.75rem !important;
    transition: 0.2s !important;
  }

  .dividerMargin {
    margin-top: -14px !important;
  }

  .activeSearch {
    width: 80px !important;
    padding: 0 7px !important;
  }

  #mainSidebar {
    width: 360px !important;
  }

  .collapseIcon {
    position: absolute;
    top: 22px;
    font-size: 30px !important;
    color: white;
    cursor: pointer;
    right: 50px !important;
  }

  #commonSidebar .MuiBox-root:first-child > div {
    opacity: 0;
    visibility: hidden !important;
    transition: 0.2s;
  }

  #parentCommonSidebar .collapseIcon {
    left: 14px;
  }

  #currentTitle {
    font-size: 16px !important;
  }

  .scroll-table {
    width: 100%;
    overflow: auto;
  }

  .scroll-table > Table {
    width: 1200px !important;
  }

  .imagePreviewSec {
    width: 115px !important;
  }

  .upload-image-btn {
    margin-left: 2px !important;
    width: 100% !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100% !important;
  }

  .scrollGRN-table {
    width: 100% !important;
    overflow: auto;
  }

  .scrollGRN-table > Table {
    width: 500px !important;
  }

  .responsiveScannerBtn {
    position: relative;
    right: 15px;
  }

  .scanButtonResponsive {
    flex-direction: column !important;
  }

  .resposiveScanner {
    margin-right: 15px !important;
  }

  .scanScrollTable {
    width: 100%;
    overflow: auto;
  }

  .scanScrollTable > Table {
    width: 1000px;
  }

  .GRNScroll {
    width: 100%;
    overflow: auto;
  }

  .GRNScroll > Table {
    width: 1400px;
  }
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiDataGrid-main {
  border-radius: 16px !important;
}

/* .MuiGrid-root .scroll-table {
    border: 1px solid rgba(75, 137, 200, 0.2) !important;
    box-shadow: 4px 4px 16px rgb(75 137 200 / 12%) !important;
    border-radius: 16px !important;
    overflow: hidden;
} */
.MuiTableRow-head {
  border: none;
}

::placeholder {
  opacity: 1 !important;
  color: #6d6d6d !important;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.MuiBackdrop-root {
  opacity: 0.699999988079071 !important;
  background: #0b1940;
}

.reset-max-width {
  max-width: none !important;
}

@keyframes pulseAnimation {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.6;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 10px;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.info-window {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 450px;
}

.info-item {
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
}

.info-title {
  font-weight: 500;
  max-width: 25%;
  min-width: 22%;
}

.info-text {
  font-size: 12px;
}

.info-item span {
  font-size: 12px;
}

.info-latlng {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.info-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.step-connector {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin: 0 auto; */
}

.step-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.step {
  padding: 0.5rem 1rem;
  border: 2px solid lightgray;
  border-radius: 20px;
  color: gray;
  font-weight: bold;
  transition: border-color 0.3s, color 0.3s;
  cursor: pointer;
  background: white;
  text-align: center;
  /* flex-shrink: 0; */
}

.active-step {
  border: 3px solid #0C71D7 !important;
}

.connector-line {
  flex: 1;
  height: 2px;
  background-color: lightgray;
  margin: 0 0.5rem;
  transition: background-color 0.3s;
}

.connector-line.active-line {
  background-color: blue;
}

.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

.shipment-search .search-box {
  position: relative;
}
